import dayjs, { OpUnitType, QUnitType, unix, extend } from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

import { UTCTimestamp } from "@/shared/types/common-types";
import type { DateRangeDurationConfig, DateTimeRange } from "@/shared/ui/ui-date-range-picker";

extend(duration);
extend(relativeTime);
extend(utcPlugin);
extend(timezonePlugin);

export type DayjsDuration = duration.Duration;

export const DEFAULT_SINCE_TIME_FORMAT = "Do [of] MMMM YYYY";
export const DEFAULT_MONTH_DAYS_FORMAT = "YYYY/MM/DD";
export const DEFAULT_HOURS_MINUTES_FORMAT = "HH:mm";
export const DEFAULT_FULL_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_FULL_DATE_FORMAT = `${DEFAULT_MONTH_DAYS_FORMAT} ${DEFAULT_FULL_TIME_FORMAT}`;
export const DEFAULT_DATE_TIME_LOCAL_INPUT = "YYYY-MM-DDTHH:mm";
export const DEFAULT_DATE_TIME_RU_FORMAT = "DD.MM.YYYY HH:mm";

export const TimeFormatShortcutMap = {
	FullDate: DEFAULT_FULL_DATE_FORMAT,
	ShortDate: DEFAULT_MONTH_DAYS_FORMAT,
	FullTime: DEFAULT_FULL_TIME_FORMAT,
	ShortTime: DEFAULT_HOURS_MINUTES_FORMAT,
	SinceTime: DEFAULT_SINCE_TIME_FORMAT,
} as const;

export type TimeShortcut = keyof typeof TimeFormatShortcutMap;

const QUARTER_HOUR_IN_SECONDS = 900;
const HALF_HOUR_IN_SECONDS = 1800;
const ONE_HOUR_IN_SECONDS = 3600;
const TWO_HOURS_IN_SECONDS = 7200;
const SIX_HOURS_IN_SECONDS = 21600;
const TWELVE_HOURS_IN_SECONDS = 43200;

const DEFAULT_DOWN_TIME = "00:00:00";
const DEFAULT_SHORT_DOWN_TIME = "00:00";

// CONVERTERS

/**
 * Calculate downtime
 * @param time - unix timestamp
 * @param showSecond - flag activating mod for adding seconds to the result
 * @returns elapsed time in the format 000:00:00 or 000:00
 */
export const getDowntime = (time: number, showSecond: boolean = true): string => {
	if (time === 0) return showSecond ? DEFAULT_DOWN_TIME : DEFAULT_SHORT_DOWN_TIME;

	const currentDate = dayjs();

	const date = unix(time);

	const hours = Math.max(currentDate.diff(date, "hours"), 0);
	const minutes = Math.max(currentDate.diff(date, "minutes") - hours * 60, 0);
	const seconds = Math.max(currentDate.diff(date, "seconds") - (minutes * 60 + hours * 60 * 60), 0);

	let result = `${addZeroDate(hours, 3)}:${addZeroDate(minutes, 2)}`;

	if (showSecond) result += `:${addZeroDate(seconds, 2)}`;

	return result;
};

const addZeroDate = (time: string | number, length: number): string => {
	let convertedTime = String(time);

	if (convertedTime.length < length) {
		for (let i = convertedTime.length; convertedTime.length < length; i += 1) {
			convertedTime = `0${convertedTime}`;
		}
	}

	return convertedTime;
};

/**
 * Convert unix timestamp to human UTC
 * @param time - unix timestamp
 * @param template - parse format (example: "MM-DD-YYYY")
 * @returns UTC time in template format
 */
export const unixToUTCFormat = (time: number, template?: string) =>
	unix(time).utc().format(template);

/**
 * Convert date using shortcut
 * @param time - unix timestamp
 * @param shortcut - shortcut for select time format
 * @returns UTC time in selected format
 */
export const unixToDateFormat = (time: number, shortcut: TimeShortcut) =>
	unixToUTCFormat(time, TimeFormatShortcutMap[shortcut]);

/**
 * Convert unix timestamp to since UTC format
 * @param time - unix timestamp
 * @returns UTC time in format "Do [of] MMMM YYYY"
 */
export const unixToSinceFormat = (time: number) => unixToDateFormat(time, "SinceTime");

/**
 * Convert unix timestamp to milliseconds
 * @param time - unix timestamp
 * @returns milliseconds
 */
export const unixToMs = (time: number) => time * 1000;

export const msToUnix = (time: number) => dayjs(time).unix();

export const hoursToSeconds = (hours: number) => hours * 60 * 60;

/**
 * Convert timestamp to UTC
 * @param timestamp - unix timestamp
 * @returns - timestamp to UTC
 */
export const localTimestampToUTC = (timestamp: UTCTimestamp) =>
	// in the future we need to rewrite
	// this helper taking into account the selected time zone
	// unix(timestamp).utc().unix();
	timestamp as number;

/**
 * Convert string date to UTC unix timestamp
 * @param time time in string format
 * @returns UTC time in unix seconds
 */
export const stringDateToUnix = (time: string): number => dayjs(time).utc().unix();

/**
 * Convert period to string representation
 * @param seconds - time period in seconds
 * @returns - period in the format "00h 00m"
 */
export const secondsToFormattedPeriod = (seconds: number) => {
	const period = dayjs.duration(seconds, "seconds");

	return `${Math.floor(period.asHours())}h ${period.minutes()}m`;
};

/**
 * Getting UNIX format from date string with translation to UTC
 * (for convert datetime-local input value)
 * @param time local time in string format
 * @returns UNIX local time from UTC
 */
export const localStringTimeToUTCUnix = (time: string) => {
	if (!time) return time;

	return dayjs.utc(time).unix();
};

/**
 * Convert Date object to UTC unix timestamp
 * (only for mocks data!)
 * @param date - Date object
 * @returns - unix timestamp
 */
export const dateToUnix = (date: Date) => dayjs(date).utc().unix();

export const unixToISOString = (time: number) => unix(time).toISOString();

export const ruDateFormat = (dateTime: string) =>
	dayjs(dateTime).format(DEFAULT_DATE_TIME_RU_FORMAT);

// GETTERS

export const getCurrentUnix = () => dayjs().utc().unix();

/**
 * Returns the string of relative time from now
 * @param time - unix timestamp
 * @param showPastSuffix - suffix add flag (in / ago)
 * @returns string of relative time
 */
export const formatElapsedTime = (time: number, showPastSuffix = true) =>
	unix(time).utc().fromNow(!showPastSuffix);

/**
 * Get time range for DatePicker from duration
 * @param duration - period from end point time
 * @param endPoint - end point time in unix timestamp
 * @returns - date time range
 */
export const getUTCRangeFromDuration = (
	duration: DateRangeDurationConfig = {},
	endPoint?: number
): DateTimeRange => {
	const dayDuration = dayjs.duration(duration);

	const end = endPoint ? getDayjsFromUnix(endPoint) : getCurrentDayjs();
	const start = end.subtract(dayDuration);

	return [start, end];
};

/**
 * Get time range for DatePicker
 * @param startPoint - start point time in unix timestamp
 * @param endPoint -  end point time in unix timestamp
 * @returns - date time range
 */
export const getUTCRange = (startPoint: number, endPoint?: number) => {
	const end = endPoint ? getDayjsFromUnix(endPoint) : getCurrentDayjs();
	const start = getDayjsFromUnix(startPoint);

	return [start, end];
};

/**
 * Method for converting Unix Timestamp date format into format for datetime-local input
 * @param time - Unix Timestamp
 * @returns returns a UTC date string value
 */
export const getUTCDateTimeInputValue = (time: number | "") => {
	if (time) {
		return unix(time).utc().format(DEFAULT_DATE_TIME_LOCAL_INPUT);
	}

	return time;
};

/**
 * Get a string representation of the current time
 * @returns UTC time in format "MM:DD:hh:mm"
 */
export const getCurrentFormatDate = () => unixToDateFormat(getCurrentUnix(), "FullDate");

export const getCurrentDayjs = () => dayjs().utc();

export const getDayjsFromUnix = (time: number) => unix(time).utc();

export const getDayjsFromMs = (time: number) => dayjs(time).utc();

export const getDateTimeRangeFromUtc = (from: number, to: number): DateTimeRange => [
	getDayjsFromUnix(from),
	getDayjsFromUnix(to),
];

/**
 * Checking if a date is future tense
 * @param time - time in milliseconds or string format
 * @returns boolean
 */
export const checkFutureDate = (time: string | number) => {
	const currentTime = dayjs().utc();
	const selectTime = dayjs(time).utc();

	return selectTime.isAfter(currentTime);
};

// CALCULATIONS

/**
 * Calculates the percentage offset of the numStatus point
 * relative to the startPoint coordinate
 * in the range between startPoint and endPoint.
 * @param numStatus - coordinate of the desired point
 * @param startPoint - starting coordinate
 * @param endPoint - end coordinate
 * @returns offset percentage
 */
export const calculateStep = (numStatus: number, startPoint: number, endPoint: number) =>
	(100 * ((numStatus - startPoint) / (endPoint - startPoint))).toFixed(2);

/**
 * Choose step time period
 * @param timePeriod - time period in seconds
 * @returns time step in seconds
 */
export const chooseStep = (timePeriod: number) => {
	// < 1hour
	if (timePeriod < ONE_HOUR_IN_SECONDS) {
		return QUARTER_HOUR_IN_SECONDS;

		// from 1h to 6h
	}
	if (timePeriod >= ONE_HOUR_IN_SECONDS && timePeriod < SIX_HOURS_IN_SECONDS) {
		return HALF_HOUR_IN_SECONDS;

		// from 6h to 12h
	}
	if (timePeriod >= SIX_HOURS_IN_SECONDS && timePeriod < TWELVE_HOURS_IN_SECONDS) {
		return ONE_HOUR_IN_SECONDS;

		// from 12h to 24h
	}
	if (timePeriod >= TWELVE_HOURS_IN_SECONDS) {
		return TWO_HOURS_IN_SECONDS;
	}

	return QUARTER_HOUR_IN_SECONDS;
};

/**
 * Calculates difference in days between two Dates in UTC
 * irrespective to dates time system (DST, etc.)
 * @param first - earliest between two dates in unix format
 * @param last - latest between two dates in unix format
 * @param unit - date difference unit (by default value "day")
 * @returns number of unit between dates
 */
export const dateDiff = (first: number, last: number, unit: QUnitType | OpUnitType = "day") => {
	// Discard the time and time-zone information.
	const utc1 = unix(first).utc();
	const utc2 = unix(last).utc();

	return utc2.diff(utc1, unit);
};

export const isUTCTimezone = (timezone: string): timezone is "UTC" => timezone === "UTC";

/**
 * Returns the system timezone.
 * @returns {string} The system timezone.
 */
export const getSystemTimezone = (): string => dayjs.tz.guess();

/**
 * Calculates the UTC offset in minutes for a given timezone.
 * If the timezone is UTC, the offset is 0.
 *
 * @param timezone - The timezone to calculate the UTC offset for.
 * @returns The UTC offset in minutes.
 */
export const getUTCOffset = (timezone: string) => {
	if (isUTCTimezone(timezone)) {
		return 0;
	}

	const offsetInMinutes = dayjs().tz(timezone).utcOffset();

	return offsetInMinutes;
};

export const getSystemUTCOffset = () => dayjs().utcOffset();

/**
 * Returns the UTC offset text for a given timezone.
 * @param timezone - The timezone to get the UTC offset for ("UTC" or a resolved timezone, ie "Europe/Moscow").
 * @returns The UTC offset text in the format "UTC+X" or "UTC-X".
 */
export const getUTCOffsetText = (timezone: string) => {
	const offsetInMinutes = getUTCOffset(timezone);

	const hours = Math.floor(Math.abs(offsetInMinutes) / 60);

	const hoursSign = offsetInMinutes > 0 ? "+" : "-";

	const offsetText = `UTC${hoursSign}${hours}`;
	return offsetText;
};

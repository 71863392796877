import React from "react";
import { Result } from "antd";
import { observer } from "mobx-react-lite";

import { AbilityType } from "@/shared/types/abilities-types";

import { useHasAbilities } from "../../hooks/use-has-abilities";

export interface PermissionProps {
	abilityName: AbilityType;
}

const NO_PERMISSIONS_TEXT = "You don't have enough rights...";
const NO_PERMISSION_SUB_TEXT =
	"Please try updating your abilities or make sure you have enough permissions to view this block";

export interface PermissionWrapperProps extends PermissionProps {
	children: React.ReactNode;
	noPermissionsChildren?: React.ReactNode;
	showInfoMsg?: boolean;
}

export const PermissionWrapper = observer(
	({
		abilityName,
		children,
		noPermissionsChildren,
		showInfoMsg = true,
	}: PermissionWrapperProps) => {
		const isHasAbility = useHasAbilities(abilityName);

		if (isHasAbility) return children;

		if (noPermissionsChildren) return noPermissionsChildren;

		if (showInfoMsg)
			return <Result title={NO_PERMISSIONS_TEXT} subTitle={NO_PERMISSION_SUB_TEXT} />;

		return null;
	}
);
